





























































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { mask } from 'vue-the-mask'

@Component({
  directives: {
    mask,
  },
})
export default class ShopDataBudget extends Vue {
  saveLoading = false

  budgetsDates = []
  budgetsData = []
  inputDate = null

  currentQuantityTyres = null
  currentYearPercentage = null
  currentTotalAmount = null

  get requestData() {
    return {
      date: this.inputDate,
      quantity_tyres: this.currentQuantityTyres,
      year_percentage: this.currentYearPercentage,
      total_amount: this.currentTotalAmount,
    }
  }

  created() {
    this.$axios.get('/v3/shop/data/budget/get_initial_data').then((response) => {
      this.budgetsDates = response.data.data.budgets.dates
      this.budgetsData = response.data.data.budgets.data
    })
  }

  save() {
    const self = this

    self.saveLoading = true
    this.$axios.post('/v3/shop/data/budget/save', this.requestData).then((response) => {
      if (self.budgetsData[self.inputDate] === null || self.budgetsData[self.inputDate] === undefined) {
        self.budgetsData[self.inputDate] = {
          quantityTyres: null,
          yearPercentage: null,
          totalAmount: null,
        }
      }
      if (self.budgetsDates[self.inputDate] === null || self.budgetsDates[self.inputDate] === undefined) {
        self.budgetsDates.push(self.inputDate)
      }
      self.budgetsData[self.inputDate].quantityTyres = self.currentQuantityTyres
      self.budgetsData[self.inputDate].yearPercentage = self.currentYearPercentage
      self.budgetsData[self.inputDate].totalAmount = self.currentTotalAmount
      self.saveLoading = false
    })
  }

  @Watch('inputDate')
  onInputDateChanged() {
    this.currentQuantityTyres = null
    this.currentYearPercentage = null
    this.currentTotalAmount = null

    if (
      this.inputDate !== '' &&
      this.budgetsData[this.inputDate] !== null &&
      this.budgetsData[this.inputDate] !== undefined
    ) {
      const currentBudget = this.budgetsData[this.inputDate]
      this.currentQuantityTyres = currentBudget.quantityTyres
      this.currentYearPercentage = currentBudget.yearPercentage
      this.currentTotalAmount = currentBudget.totalAmount
    }
  }
}
